@import "../../../App.scss";

.add_button {
    margin-bottom: 12px;
}

.entity_row {
    padding: 12px;
    @extend .row-odd;
    @extend .clickable;
    @extend .flex-wrapper;
    justify-content: space-between;
    align-items: center;
}

.entity_row.selected {
    @extend .selected;
}

.icon_list_holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
        margin-left: 8px;
    }
}

.warning_icon {
    svg {
        fill: tomato;
    }
}
