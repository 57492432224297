.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.exportButton {
  margin-bottom: 1rem;
}

.aggregatedStat {
  font-size: 2.5rem;
}

.comments {
  height: 500px;
  overflow: scroll;
}

.commentInfoContainer {
  color: rgb(178, 178, 178);
  margin-bottom: 0.25rem
}

.commentRow {
  margin-bottom: 1.75rem;
}

.comment {
  color: #666;
  font-size: 16px;
  border-left: solid 2px #999;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.ratingByStarsRow {
  display: flex;
  align-items: center;
}

.ratingByStarsNbRatings {
  width: 35px;
  margin: 0 1rem;
}

.ratingPercentageContainer {
  flex-grow: 1;
  height: 10px;
  background: #ececec;
}

.ratingPercentage {
  height: 10px;
  background: #FFD700;
}