.marginTop {
  margin-top: 30px;
}

.marginBottom {
  margin-bottom: 30px;
}


.marginY {
  margin: 30px 0;
}


.deleteIcon {
  padding:0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
