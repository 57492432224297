// Customization of the ant design Description
.ant-descriptions-item-label {
  font-weight: bold !important;
}

.ant-descriptions-title {
  margin-top: 24px;
}

.description-wrapper {
  background-color: white;
  padding: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.small_separation_text {
  color: #4287f5;
  font-size: 0.9em;
  font-weight: 500;
  margin: 14px 0 0 0;
  display: inline-block;
}

$soft-blue: #cfd8dc;

@mixin row($pattern) {
  &:nth-child(#{$pattern}) {
    background-color: $soft-blue;
  }

  &.selected {
    border-left: 3px solid #546e7a;
  }

  &.clickable {
    cursor: pointer;
  }
}

.row-even {
  @include row(even);
}

.row-odd {
  @include row(odd);
}

.search-input {
  width: 200px !important;
  margin-right:20px!important;
 // margin-bottom: 20px !important;
}

// Customization of the labels
.ant-form-item {
  display: flex !important;
  flex-direction: column !important;
}

/*
    This is just for fun. It will probably end up being a bad idea, but I will do it anyway
*/
$mobile-trigger: 600px;
$sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@each $size in $sizes {
  .flex-#{$size} {
    width: calc((100% * $size / 12) - 16px);
    margin: 8px 8px;
    @media (max-width: $mobile-trigger) {
      width: 100%;
      margin: 8px 0;
    }
  }
}

.flex-no-margin {
  margin: 0;
}

.drag-and-drop__item {
  border: 1px solid black;
  padding: 5px;
}


.ant-list-items {
  &--header {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
    background: #fafafa;
    margin: 0;
  }


  .ant-list-item {
    flex-wrap: wrap;
    background: #FFF;
    border-bottom: 1px solid #cecece;
    margin: 10px 0;
    padding: 12px !important;


    .ant-row {
      width: 100%;
    }
  }
}

